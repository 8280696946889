.final_stage {
    .subtitle-stage {
        color: #262626;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
    }
      
    @media screen and (max-width: 900px) {
        margin-top: 5%;
    }

    // SharpeRatioCompute
    .row_sharpe_ratio {
        margin-top: 2%;
        justify-content: center;

        label {
            margin-top: auto;
            margin-bottom: auto;
        }

        .input_sharpe_ratio {
            max-width: 90px;
            margin-left: 8px;
            margin-right: 6px;
        }
    
        .btn_sharpe_ratio {
            background-color: #1890FF;
            border-color: #1890FF;
            color: white;
        }
    }

    .row_message {
        justify-content: center;
        margin-top: 1%;
        font-family: 'Poppins', sans-serif;

        .green_txt {
            color: #77d13f;    
        }

        .red_txt {
            color: #f5222d;    
        }
    }
}
// EndGame
.end_screen {
    padding: 5%;
    height: 100%;
    display: flex;

    .text_class {
        display: inline-block;
        vertical-align: middle;
        margin: auto;

        .blue_text {
            font-family: 'Poppins', sans-serif;
            color: #1890FF;
        }
    }
}