.create_session_modal {
    width: 60vw !important;

    .ant-modal-title {
        color: #1890FF;
        font-family: 'Poppins', sans-serif;
    }

    .col_style {
        padding-right: 5%;
    }

    .subtitle {
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        color: #262626;
    }

    .label_style {
        font-family: 'Poppins', sans-serif;
        color: #262626; 
    }

    .ant-select {
        width: 100%;
        margin-bottom: 4%;
    }

    .ant-select-selection-item {
        text-align: center;
    }

    .ant-input {
        text-align: center;
        margin-bottom: 4%;
    }

    .group {
        display: flex;

        h4 {
            margin-right: 1%;
        }     
    }

    .btn_row {
        text-align: center;
        margin-top: 2%;
        min-height: 30px;

        .hide_btn {
            display: none;
        }

        button {
            background-color: #1890FF;
            border-color: #1890FF;
            color: white;
            border-radius: 5px;
            font-family: 'Poppins', sans-serif;
        }

        .join_btn {
            background-color: #73d13d;
            border-color: #73d13d;
            color: white;
            border-radius: 5px;
            font-family: 'Poppins', sans-serif;
            margin-left:2%
        }
    }

    .total_row {
        border-top: 1px solid #e2e2e2;
        padding-top: 2%;

        .total_label {
            font-family: 'Poppins', sans-serif;
            font-style: italic;
            color: #8C8C8C;
        }
    }

    .warning_class {
        color: red;
    }

    .success_class {
        color: #73d13d;
    }

    .show_global_benchmark {
        display: inherit;
    }

    .hide_global_benchmark {
        display: none;
    }

    .input_number {
        width: 100%;
        
        .ant-input-number-input {
            text-align: center !important;
        }
    }

    @media screen and (max-width: 900px) {
        width: 80vw !important;
    }

    @media screen and (max-width: 650px) {
        width: 90vw !important;
    }
}