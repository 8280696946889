.stage_container {
    min-height: 90%;

    .subtitle-stage {
        color: #262626;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
    }

    @media screen and (max-width: 900px) {
        margin-top: 10%;
    }
}

.stage_container_no_content {
    height: 94%;
    display: flex;

    .subtitle-stage-msg {
        display: inline-block;
        vertical-align: middle;
        margin: auto;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        color: #262626;
        font-weight: bold;
    }  
}

// BenchmarkAndLeverage
.benchmark_stage_container {
    .subtitle-stage, .collapse_subtitle {
        color: #262626;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
    }

    .collapse_subtitle {
        padding: 5px;
    }

    .info_row {
        margin-bottom: 2%;

        .info-subtitle {
            color: #8c8c8c;
            font-family: 'Poppins', sans-serif;
        }
    }

    .ant-collapse {
        max-width: 810px;
        margin: auto;
    }

    @media screen and (max-width: 1500px) {
        .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
            padding-right: 1px !important;
            padding-left: 2px !important;
        }
    
        .ant-collapse-content > .ant-collapse-content-box {
            padding-left: 1px !important;
            padding-right: 1px !important;
            padding-top: 3px !important;
            padding-bottom: 3px !important;
        }
    }

    @media screen and (max-width: 900px) {
        margin-top: 10%;
    }
}

// WelcomeScreen
.welcome_screen {
    padding: 5%;
    height: 100%;
    display: flex;

    .text_class {
        display: inline-block;
        vertical-align: middle;
        margin: auto;

        .blue_text {
            font-family: 'Poppins', sans-serif;
            color: #1890FF;
        }
    }
}

// InstrumentsReturn
.instruments_return {
    .margin_top_class {
        margin-top: 30px;
    }

    .margin_bottom_class {
        margin-bottom: 30px;
    }

    .row_arrow {
        display: flex;
        justify-content: flex-end;  
        margin-right: 5%;

        .title_class {
            margin-right: 48%;
        } 
    }

    @media screen and (max-width: 900px) {
        margin-top: 10%;
    }
}
