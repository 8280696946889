// general
%dot {
    height: 10px;
    width: 10px;   
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 1px;
    display: flex;
}

.playing_dot {
    @extend %dot;
    background-color: #73d13d;
}

.offline_yellow_dot {
    @extend %dot;
    background-color: #fadb14;
}

.offline_dot {
    @extend %dot;
    background-color: #f5222d;
}

// Login
.login_div {
    padding-top: 13vh;
}

@media screen and (max-width: 700px) {
    .login_div {
        padding-top: 3vh;
    }
}

// pagination
.ant-pagination {
    text-align: end;
    margin-top: 2%;
    margin-bottom: 2%;
}

@media screen and (max-width: 700px) {
    .ant-pagination-item {
        margin-right: 6px !important;
    }
}

@media screen and (max-width: 300px) {
    .ant-pagination-item, .ant-pagination-prev, .ant-pagination-jump-next {
        margin-right: 1px !important;
    }
}

// TrainerScenarios
.trainer_scenarios {
    min-height: 87vh;

    .star_btn:hover, .star_btn:active, .star_btn:focus {
        border-color: #d9d9d9;
    }

    .initial_star {
        color: #8c8c8c;
    }

    .fav_star {
        color: #ffec3d;
    }

    .preview_btn {
        background-color: #1890FF;
        border-color: #1890FF;
        color: white;
        border-radius: 5px;
    }

    .instrument_btn {
        margin-right: 1%;
    }
    
    .instrument_btn:hover {
        color: rgba(0, 0, 0, 0.85);
        border-color: #d9d9d9;
        cursor: context-menu;
    }

    .ant-table-thead > tr > th:nth-last-child(2) {
        width: 10px !important;
    }
}

// TrainerSessions + Team Sessions
.trainer_team_sessions {
    min-height: 87vh;

    .playing_status {
        color: #73d13d;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
    }

    .paused_status {
        color:#fa622d;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
    }

    .scheduled_status {
        color: #c1a704;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
    }

    .complete_status {
        color:#1890ff;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
    }

    .join_btn {
        background-color: #73d13d;
        border: #73d13d;
        color: white;
        border-radius: 5px;
        min-width: 95px;
    }

    .completed_btn {
        background-color: #1890ff;
        border: #1890ff;
        color: white;
        border-radius: 5px;
        min-width: 95px;
    }

    .icon_btn {
        min-width: auto !important;
    }

    .instrument_btn {
        margin-right: 1%;
    }

    .instrument_btn:hover {
        color: rgba(0, 0, 0, 0.85);
        border-color: #d9d9d9;
        cursor: context-menu;
    }

    .ant-table-cell:last-child {
        padding: 0 0 !important;
    }
}

// TrainerClasses
.trainer_classes {
    min-height: 87vh;

    .waiting_msg_div {
        text-align: center;

        .waiting_msg {
            font-family: 'Poppins', sans-serif;
            color: #1890FF;
            width: 100%; 
        }
    }

    .team_title {
        font-family: 'Poppins', sans-serif;
        color: #262626;
        font-weight: bold;
    }

    .div_info {
        margin-top: 1%;
        margin-bottom: 2%;

        .online_info {
            color: #595959;
            margin-right: 1%;
            font-family: 'Poppins', sans-serif;
        }
    
        .value_info {
            color: #595959;
            font-weight: bold;
            margin-right: 10%;
            font-family: 'Poppins', sans-serif;
        }
    }

    .player_btn {
        margin-right: 1%;

        .avatar_name {
            margin-left: 4%;
        }
    }

    .player_btn:hover {
        color: rgba(0, 0, 0, 0.85);
        border-color: #d9d9d9;
        cursor: context-menu;
    }

    .player_btn_inactive {
        opacity: 0.4;
        margin-right: 1%;

        .avatar_name {
            margin-left: 4%;
        }
    }

    .player_btn_inactive:hover{
        color: rgba(0, 0, 0, 0.85);
        border-color: #d9d9d9;
        cursor: context-menu;
        opacity: 0.4;
    }

    .ant-table-thead > tr > th:first-child {
        width: 150px !important;
    }

    .ant-table-thead > tr > th:nth-last-child(2) {
        width: 100px !important;
    }

    .ant-avatar-sm {
        width: 19px !important;
        height: 19px !important;
        line-height: 19px !important;
    }

    @media screen and (max-width: 1000px) {
        .div_info {
            .value_info:first-child {
                margin-right: 3px;
            }

            .value_info:last-child {
                margin-right: 0px;
            }
        }
    }
}

// TrainerGame
.game_container {
    background-color: #f0f2f5 !important;

    .first_col_style {     
        margin-right: 1%;
    }

    .second_col_style {
        padding: 1%;
        background-color: white;

        .ant-table-thead > tr > th:first-child {
            width: 10px !important;
        }
    }

    .session_status {
        color: #1890FF;
    }

    .subtitle_game {
        color: #262626;
        font-family: 'Poppins', sans-serif;
    }
      
    .row_class {
        background-color: white;
        flex-direction: column;
        padding: 1%;
        margin-bottom: 2%;

        .title_row {
            border-bottom: 1px solid #e2e2e2;
            margin-bottom: 1%;
    
            .title {
                color: #262626;
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
            }
        }

        .first_btn_start {
            background-color: #1890FF;
            border-color: #1890FF;
            color: white;
            margin-right: 3%;
            border-radius: 5px;
            min-width: 70px;
        }

        .first_btn_pause {
            background-color: #fadb14;
            border-color: #fadb14;
            color: #262626;
            margin-right: 3%;
            border-radius: 5px;
            min-width: 70px;
        }

        .first_btn_disabled {
            background-color: #fadb14;
            border-color: #fadb14;
            color: #262626;
            margin-right: 3%;
            border-radius: 5px;
            opacity: 0.5;
            min-width: 70px;
        }
    
        .end_btn {
            background-color: #f5222d;
            border-color: #f5222d;
            color: white;
            border-radius: 5px;
            min-width: 89px;
        }
    }

    .row_class_game {
        background-color: white;
        padding: 4% 2%;

        .game_stage {
            color: #262626;
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
        }

        .page_class {
            width: 96%;
            height: 330px;
            border: 1px solid #e2e2e2;
            overflow: auto;
        }

        .hide_class {
            display: none;
        }

        .page_class:hover {
            border: 1px solid #1890FF;
            cursor: pointer;
        }

        .bottom_row {
            width: 100%;
            margin-top: 2%;
    
            .btn {
                width: 23%;
                margin-right: 1%;
            }
        
            .play_btn {
                width: 23%;
                background-color: #1890FF;
                border-color: #1890FF;
                color: white;
                margin-right: 3%;
            }

            .disabled_btn {
                width: 23%;
                background-color: #595959;
                border-color: #595959;
                color: white;
                margin-right: 3%; 
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .second_col_style {
            margin-top: 2%;
        }
    }

    @media screen and (max-width: 750px) {
        .row_class_game {
            flex-direction: column !important;

            .col_game_stage {
                max-width: 100% !important;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .row_class_game {
            .bottom_row {        
                .btn {
                    width: 15%;
                    margin-right: 1%;
                }

                .btn:nth-last-child(3) {
                    width: 30%;
                }
            
                .play_btn {
                    width: 30%;
                    background-color: #1890FF;
                    border-color: #1890FF;
                    color: white;
                    margin-right: 3%;
                }
            }
        }
    }
}

// Benchmark Portfolio
.benchmark_portfolio {
    background-color: white;
    width: -webkit-fill-available;
    min-height: 87vh;
    padding: 2%;

    .instr_head {
        max-width: 120px !important;
    }

    .benchmark_table {
        width: fit-content;

        .ant-table-thead > tr > th {
            min-width: 120px !important;
        }

        .ant-table-thead > tr > th:first-child {
            min-width: 10px !important;
        }
    }
}

// Allocations
.allocations {
    padding: 2%;
    min-height: 87vh;

    .instr_head {
        max-width: 90px !important;
    }

    .allocations_table {
        width: fit-content;

        .ant-table-thead > tr > th {
            min-width: 120px !important;
        }

        .ant-table-thead > tr > th:first-child {
            min-width: 10px !important;
        }

        .ant-table-row:hover {
            cursor: pointer;

            td {
                border-bottom: 1px solid #1890FF !important;
                border-top: 1px solid #1890FF !important;
            }
        }
    }

    .red_class {
        color: #f5222d;
        font-weight: bold;
    }

    .green_class {
        color: #73d13d;
        font-weight: bold;
    }

    .bold_text {
        font-weight: bold;
    }
}

// TeamClasses
.team_classes {
    min-height: 87vh;

    .waiting_msg_div {
        text-align: center;

        .waiting_msg {
            font-family: 'Poppins', sans-serif;
            color: #1890FF;
            width: 100%; 
        }
    }

    .team_title {
        font-family: 'Poppins', sans-serif;
        color: #262626;
        font-weight: bold;
    }

    .player_btn {
        margin-right: 1%;

        .avatar_name {
            margin-left: 4%;
        }
    }

    .player_btn:hover {
        color: rgba(0, 0, 0, 0.85);
        border-color: #d9d9d9;
        cursor: context-menu;
    }

    .player_btn_inactive {
        opacity: 0.4;
        margin-right: 1%;

        .avatar_name {
            margin-left: 4%;
        }
    }
  
    .player_btn_inactive:hover{
        color: rgba(0, 0, 0, 0.85);
        border-color: #d9d9d9;
        cursor: context-menu;
        opacity: 0.4;
    }
}

// TeamGame
.team_game {
    background-color: #f0f2f5 !important; 
    min-height: 87vh;

    .row_team_game {
        min-height: inherit;
    }
    
    .first_col_style {     
        margin-right: 1%;

        .instruments_details {
            padding: 1%;
            background-color: white;
            margin-bottom: 3%;
        }

        .portfolio_table {
            padding: 2%;
            background-color: white;
            display: flex;
            flex-direction: column;

            .subtitle {
                font-family: 'Poppins', sans-serif;
                color: #262626;
            }
        }
    }

    .second_col_style {
        overflow: auto;
        padding: 1%;
        background-color: white;
    }

    .second_col_style:hover {
        cursor: pointer;
        border: 1px solid #1890FF;
    }

    @media screen and (max-width: 1200px) {
        .second_col_style {
            margin-top: 3%;
            min-height: 35vh;
        }
    }
}

// ReportTeam
.team_report {
    background-color: #f0f2f5 !important; 
    min-height: 87vh;

    .row_team_game {
        min-height: inherit;
    }
    
    .first_col_style {     
        margin-right: 1%;

        .instruments_details {
            padding: 1%;
            background-color: white;
            margin-bottom: 3%;
        }

        .portfolio_table {
            padding: 2%;
            background-color: white;
            display: flex;
            flex-direction: column;

            .subtitle {
                font-family: 'Poppins', sans-serif;
                color: #262626;
            }
        }
    }

    .second_col_style {
        overflow: auto;
        padding: 1%;
        background-color: white;
    }

    .second_col_style:hover {
        cursor: pointer;
        border: 1px solid #1890FF;
    }

    @media screen and (max-width: 1200px) {
        .second_col_style {
            margin-top: 3%;
            min-height: 35vh;
        }
    }
}


// ReportTrainer
.report_trainer_container {
    padding: 0 !important;
    background-color: #f0f2f5 !important;

    .first_col_style {     
        margin-right: 1%;
    }

    .session_status {
        color: #1890FF;
    }

    .subtitle_game {
        color: #262626;
        font-family: 'Poppins', sans-serif;
    }
      
    .row_class {
        background-color: white;
        flex-direction: column;
        padding: 1%;
        margin-bottom: 1%;

        .title_row {
            border-bottom: 1px solid #e2e2e2;
            margin-bottom: 1%;
    
            .title {
                color: #262626;
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
            }
        }
    }

    .row_class_game {
        background-color: white;
        padding: 2%;

        .game_stage {
            color: #262626;
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
        }

        .page_class {
            width: 96%;
            height: 57vh;
            border: 1px solid #e2e2e2;
            overflow: auto;
        }

        .hide_class {
            display: none;
        }

        .page_class:hover {
            border: 1px solid #1890FF;
            cursor: pointer;
        }

        .bottom_row {
            width: 100%;
            margin-top: 2%;
    
            .btn {
                width: 23%;
                margin-right: 1%;
            }
        }
    }

    @media screen and (max-width: 750px) {
        .row_class_game {
            flex-direction: column !important;

            .col_game_stage {
                max-width: 100% !important;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .row_class_game {
            .bottom_row {        
                .btn {
                    width: 15%;
                    margin-right: 1%;
                }
            }
        }
    }
}