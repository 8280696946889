.sider_instruments_teams {
    background-color: #FFFFFF !important;

    @media screen and (max-width: 700px) {   
        width: 33vw !important;
        min-width: 33vw !important;
        max-width: 33vw !important;
        flex: 0 0 33vw !important;
    }
}

.slider_content_instruments {
    padding: 3%;
    background-color: white !important;
    font-family: "Poppins", sans-serif;
    height: 350px;
    overflow: auto;
    
    p {
        font-size: small;
    }
}