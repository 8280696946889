.top_bar {
    display: flex;
    width: 100%;
    margin-bottom: 1.5%;

    .visible_btn {
        background-color: #1890FF;
        border-color: #1890FF;
        color: white;
        margin-left: 1%;
        border-radius: 5px;
    }

    .hide_btn {
        display: none;
    }

    .select_class {
        margin-right: 1%;
        min-width: 10%;
        height: fit-content;
    }

    .search_input {
        width: 45%;
        margin-right: 1%;
    }

    .select_multiple {
        width: -webkit-fill-available;
    }

    @media screen and (max-width: 1000px) {
        flex-wrap: wrap;

        .search_input {
            width: 100%;
            margin-bottom: 1%;
        }

        .select_class {
            margin-bottom: 1%;
            width: 31%;
        }

        .select_multiple {
            margin-bottom: 1%;
        }

        .visible_btn {
            margin-left: auto;
        }
    }
}