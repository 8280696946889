.sider_game {
    background-color: #FFFFFF !important;

    .ant-menu-submenu-title {
        padding-left: 24px !important;        
    }

    .submenu-item {
        padding-left: 35px !important;
    }

    .selected_class {
        color: #1890ff;
    }

    .unselected_class {
        color: rgba(0, 0, 0, 0.85);
    }

    @media screen and (max-width: 900px) {
        display: none !important;
    }
}