.allocation_modal {
    width: 40vw !important;
    top: 30vh;
    right: -25vw;
    border: 1px solid #262626;
    border-radius: 6px;
    overflow: hidden;
    padding-bottom: 0 !important;

    .ant-modal-body {
        max-height: 500px;
        overflow: auto;
        height: 100%;
    }

    .red_class {
        color: #f5222d;
    }

    .green_class {
        color: #73d13d;
    }

    @media screen and (max-width: 1200px) {
        width: 80vw !important;
        right: -5vw;
    }

    @media screen and (max-width: 900px) {
        width: 96vw !important;
        right: 0;
    }
}