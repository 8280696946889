.allocations_report {
    .instr_head {
        max-width: 90px !important;
    }

    .allocations_table {
        width: fit-content;

        .ant-table-thead > tr > th {
            min-width: 120px !important;
        }

        .ant-table-thead > tr > th:first-child {
            min-width: 10px !important;
        }

        .ant-table-row:hover {
            cursor: pointer;

            td {
                border-bottom: 1px solid #1890FF !important;
                border-top: 1px solid #1890FF !important;
            }
        }
    }

    .red_class {
        color: #f5222d;
        font-weight: bold;
    }

    .green_class {
        color: #73d13d;
        font-weight: bold;
    }

    .bold_text {
        font-weight: bold;
    }
}