.mobile_navbar {
    display: none;
    position: sticky;
    top: 0;
    background-color: transparent;
    z-index: 1;
    width: 100%;
    font-family: 'Poppins', sans-serif;

    #menuToggle {
        display: block;
        position: absolute;
        right: 30px;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;
      
        ul li {
            list-style-type: none;
            display: contents;
        }
    }
        
    #menuToggle input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7%;
        left: -5px;
        cursor: pointer;
        opacity: 0;
        z-index: 998;
        -webkit-touch-callout: none;
        overflow: hidden;
      
        @media screen and (max-width: 700px) and (min-width: 600px) {
          margin-top: 35%
        }
    }
        
    #menuToggle span {
        display: block;
        width: 30px;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background: #1890FF;
        border-radius: 3px;
        z-index: 997;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        opacity 0.55s ease;
    }
        
    #menuToggle span:first-child {
        transform-origin: 0% 0%;
    }
        
    #menuToggle span:nth-last-child(2) {
        transform-origin: 0% 100%;
    }
        
    #menuToggle input:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -2px);
        background: white;
    }
        
    #menuToggle input:checked ~ span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
        background: white;
    }
        
    #menuToggle input:checked ~ span:nth-last-child(2) {
        opacity: 1;
        transform: rotate(-45deg);
        background: white;
    }
        
    #menu {
        position: fixed;
        width: 300px;
        margin: 0;
        top: 0;
        padding-top: 100px;
        right: 0px;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(100%, 0);
        transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
        padding-left: 0;
        height: 100vh;
        background:#212124d9;
        z-index: 996;
        overflow: hidden;
        overflow-y: scroll;

        .logout_item {
            position: absolute;
            bottom: 7%;
            width: 100%;
        }

        .ant-menu-vertical, .ant-menu-submenu-title {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }

        .ant-menu-submenu-title:focus,.ant-menu-submenu-title:active, .ant-menu-submenu-title:hover {
            background-color: transparent !important;
        }
    }
        
    #menuToggle input:checked ~ #menu {
        box-shadow: 0 0 0 100vw rgba(1, 1, 1, 0.5);
    }
        
    #menu a {
        padding: 10px 0;
        font-size: large;
        text-align: center;
        padding-left: 0 !important;
        font-weight: 600;
        text-decoration: none;
        display: list-item;
        color: white !important;
        width: fit-content;
        margin: auto;
    
        line-height: 40px;
        height: fit-content;
        padding-top: 0;
        padding-bottom: 0;
    }
        
    #menu a:hover {
        text-decoration: none;
    }
        
    #menuToggle input:checked ~ ul {
        transform: none;
        opacity: 1;
    }

    .submenu_class {
        padding: 10px 0;
        font-size: large;
        text-align: center;
        padding-left: 0 !important;
        font-weight: 600;
        display: list-item;
        color: white !important;
        width: fit-content;
        margin: auto;
        line-height: 40px;
        height: fit-content;
        padding-top: 0;
        padding-bottom: 0;
    }

    .ant-menu-submenu-arrow {
        display: none;
    }
    
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
}