.scenario_preview_modal {
    width: 60vw !important;

    .selected_class {
        color: #1890ff;
    }

    .unselected_class {
        color: rgba(0, 0, 0, 0.85);
    }

    .mobile-controls {
        display: none;
    }

    .sider_preview {
        background-color: #FFFFFF !important;
    }

    .mobile_controls {
        display: none;
    }

    .modal_title {
        display: flex;

        h2 {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            word-wrap: break-word;
            color: #1890FF;
            font-family: "Poppins", sans-serif;
        }

        .scenario_name {
            margin-left: auto;
            margin-right: auto;
            color: #595959;
        }
    }

    .ant-modal-body {
        padding-top: 0;
        padding-left: 0;
    }

    .btn-control {
        width: 50%;
    }

    .left_span {
        margin-left: 20%;
    }

    .right_span {
        margin-right: 20%;
    }

    .ant-layout-content {
        height: 70vh;
        overflow-y: auto;
    }

    .ant-layout-sider {
        height: 70vh;
    }

    .menu-div {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 68vh;
    }

    .ant-menu {
        height: -webkit-fill-available;
    }

    .ant-layout-has-sider {
        height: -webkit-fill-available !important;
    }

    .ant-layout-content {
        background-color: white !important;
    }

    
    @media screen and (max-width: 1200px) {
        width: 80vw !important;
    }

    @media screen and (max-width: 1000px) {
        width: 90vw !important;
    }

    @media screen and (max-width: 700px) {
        top: 5% !important;

        .mobile-controls {
            display: flex;
        }

        .sider_preview {
            display: none !important;
        }

        .mobile_controls {
            display: block;
        }

        .ant-modal-body {
            padding:1% !important;
        }

        .modal_title {
            flex-direction: column;
            
            .scenario_name {
                text-align: center;
            }
        }

        .ant-modal-header {
            padding-bottom: 0 !important;
        }
    }
}