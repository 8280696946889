.custom_modal {
    .ant-modal-header {
        background-color: #1890FF;
        
        .ant-modal-title {
            color: white;
            font-family: 'Poppins', sans-serif;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .ant-modal-close-x {
        display: none;
    }

    .ant-modal-body {   
        background-color: #FAFAFA;

        h1 {
            text-align: left;
            color:#595959;
            font-size: x-large;
            font-weight: bold;
            margin-bottom: 4%;
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            color: #595959;
            font-weight: 500;

            .name_div {
                width: 25%;
                margin-bottom: 1.4%;

                label:hover {
                    cursor: pointer;
                    font-weight: bold;
                }
            }
        }
    }

    .ant-modal-content {
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 1px 1px 8px #2725255c;
    }

    .ant-modal-footer {
        background-color: #FAFAFA;
        border-top: 2px solid #e4e4e4;
        padding-top: 15px;
        padding-bottom: 15px;

        .input_style {
            text-align: left;

            input {
                width: 80%;
                margin-right: 3%;
                border-radius: 6px;
            }

            button {
                background-color: #1890FF;
                border-color: #1890FF;
                color: white;
                border-radius: 5px;
            }
        }
    }

    .ant-modal-footer > button {
        display: none;
    }

    @media screen and (max-width: 700px) {
        .ant-modal-footer {
            .input_style {    
                input {
                    width: 73%;
                }
            }
        }
    }

    @media screen and (min-width: 4000px) {
        width: 25vw !important;

        .ant-modal-header .ant-modal-title {
            font-size: 30px;
        }

        .ant-modal-body {   
            h1 {
                font-size: 30px;
            }
    
            .content {
                font-size: 25px;
            }
        }

        .ant-modal-footer {
            button {
                height: 65px;
                font-size: 30px;
            }
        }
    }
}