* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    flex-grow: 1;
}

#root {
    display:flex;
    flex-direction: column;
    min-height:100vh;
}

.general_container {
    flex-grow: 1;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 1%;
    padding: 1%;
    background-color: #FFFFFF;

    @media screen and (max-width: 900px) {
        margin-top: 77px;
    }
}