.benchmark_portfolio_report {
    background-color: white;
    width: -webkit-fill-available;
    padding: 2%;

    .instr_head {
        max-width: 120px !important;
    }

    .benchmark_table {
        width: fit-content;

        .ant-table-thead > tr > th {
            min-width: 120px !important;
        }

        .ant-table-thead > tr > th:first-child {
            min-width: 10px !important;
        }
    }
}