.arrow_box_red {
    position: relative;
    background: #fe0000;
    height: 20px;
    width: 50px;
    color: white;
    text-align: center;
}

.arrow_box_red:after, .arrow_box_red:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    position: absolute;
}

.arrow_box_red:before {
    border-top-color: #fe0000;
    border-width: 32px;
    margin-left: -32px;
}

.arrow_box_green {
    position: relative;
    background: #679e0d;
    height: 20px;
    width: 50px;
    color: white;
    text-align: center;
}

.arrow_box_green:after, .arrow_box_green:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    position: absolute;
}

.arrow_box_green:after {
    border-bottom-color: #679e0d;
    border-width: 32px;
    margin-left: -32px;
}  