.ant-layout-header {
    height: 75px;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (min-width: 4000px) {
        height: fit-content;
    }
}

.header {
    background-color: #FFFFFF;
    display: flex;

    .logo {
        width: 175px;
        height: auto;
        margin-top: auto;
        margin-bottom: auto;
    }

    .ant-menu-item {
        vertical-align: sub;
        font-family: 'Poppins', sans-serif;
    }

    .web-navbar {
        width: -webkit-fill-available;

        .ant-menu-horizontal {
            border-bottom: 1px solid transparent !important;
        }
    }

    .user_style {
        width: -webkit-fill-available;
        text-align: end;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 3%;

        label {
            color: #1890FF;
            font-size: large;
            font-family: 'Poppins', sans-serif;
        }

        .anticon-user {
            color: #1890FF;
            font-size: large;
        }
    }

    @media screen and (max-width: 1100px) {
        .ant-menu-item {
            margin: 0px 10px !important;
        }

        .user_style {       
            margin-right: 0.5%;
        }

        label {
            font-size: small;
        }

        .anticon-user {
            font-size: small;
        }
    }

    @media screen and (max-width: 900px) {
        position: fixed !important;
        top: 0 !important;
        z-index: 2;
        width: 100%;
        box-shadow: 1px 1px 8px rgb(145 142 142 / 36%);  

        .web-navbar {
            display: none;
        }

        .user_style {
            display: none;
        }
    }

    @media screen and (max-width: 700px) {
        .logo {
            width: 40vw;
        }
    }
}

.mobile-navbar {
    display: none;
}

@media screen and (max-width: 900px) {
    .mobile-navbar {
        display: flex;
        position: relative;
        top: 77px;

        .list_menu {
            width: 100%;
            line-height: 28px;
            text-align: center;

            .list_item {
                padding: 0 !important;
                margin: 0 !important;
                line-height: 14px !important;
                padding-bottom: 3px !important;

                a {
                    font-family: 'Poppins', sans-serif;
                    font-weight: bold;
                    margin-right: 4px !important;
                    margin-left: 4px !important;
                }
            }
        }
    }
}