.dashboard_sidebar {
    background-color: #FFFFFF !important;

    @media screen and (max-width: 900px) {
        display: none !important;
    }
}

.sidebar {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 20%;
}

.user_account {
    position: absolute;
    bottom: 3%;

    .username_acc {
        height: 27px;

        .name {
            user-select: none;
        }
    }

    .username_acc:hover, .username_acc:active, .username_acc:focus {
        color: rgba(0, 0, 0, 0.85);
        cursor: context-menu;
        background-color: transparent !important;
    }

    .ant-menu-item-selected, .ant-menu-item-selected:active {
        background-color: transparent !important;
        color: rgba(0, 0, 0, 0.85) !important;
    }

    .ant-menu-inline, .ant-menu-item::after {
        border-right: none !important
    }    
}