.login_form_container {
    display: flex;
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    background-color: white;
    border-radius: 7px;
    overflow: hidden;
    box-shadow: 1px 1px 8px rgb(145 142 142 / 36%);  

    .left_div {
        width: 35%;
        background-color: #1890FF;
        color: white;
        display:flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        flex-direction: column;

        .graph_icon {
            width: 8vw;
            height: auto;
        }
          
        h1 {
            color: white;
            font-family: 'Poppins', sans-serif;
            font-size: x-large;    
        }
    }

    .form_container {
        text-align: center;
        padding: 5% 6%;
        width: 65%;

        h1 {
            color:#262626;
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
        }

        .button_style {
            text-align: left;

            button {
                background-color: #1890FF;
                border-color: #1890FF;
            }

            button:disabled {
                opacity: 0.5;
                color: white !important;
            }
        }

        .warning_message {
            color: #ff4d4f;
            font-size: small;
        }

        .transparent_message {
            visibility: hidden;
        }

        .spinner_div {
            min-height: 30px;
        }
    }

    @media screen and (max-width: 1600px) {
        width: 70%;
        margin-left: 15%;
        margin-right: 15%;
    }

    @media screen and (max-width: 1200px) {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;

        .left_div {
            .graph_icon {
                width: 10vw;
                margin-top: 7%;
            }
        }
    }

    @media screen and (max-width: 700px) {
        padding-bottom: 8%;
        flex-direction: column;
        border-radius: 11px;

        .left_div {
            width: 100%;

            .graph_icon {
                width: 15vw;
                margin-top: 7%;
            }

            h1 {
                margin-bottom: 7%;
            }
        }

        .form_container {
            width: 100%;
            padding-top: 12%;

            h1 {
                display: none;
            }

            .button_style button {
                width: 100%;
            }
        }
    }

    @media screen and (min-width: 4000px) {
        .left_div h1 {
            font-size: 45px;
        }

        .form_container {
            h1 {
                font-size: 50px;
            }

            .button_style button {
                height: 65px;
                font-size: 30px;
            }

            .warning_message {
                font-size: 25px;
            }
        }
    }
}

@media screen and (min-width: 4000px) {
    .ant-input-prefix, .ant-input, .ant-input-suffix {
        font-size: 40px;
    }

    .ant-form-item-explain-error {
        font-size: 25px;
    }

    .ant-form-item-control {
        line-height: 8;
    }
}