.sider_game_team {
    background-color: #FFFFFF !important;

    .hide_item {
        display: none;
    }

    .show_item {
        display: list-item;
        list-style-type: none !important;
    }

    .selected_class {
        color: #1890ff;
    }

    .unselected_class {
        color: rgba(0, 0, 0, 0.85);
    }

    .show_submenu {
        display: block;
    }

    @media screen and (max-width: 900px) {
        display: none !important;
    }
}