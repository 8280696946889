.stage_modal {
    width: 60vw !important;

    .ant-modal-body {
        min-height: 80vh;
        max-height: 80vh;
        overflow: auto;
    }

    @media screen and (max-width: 900px) {
        width: 80vw !important;
    }

    @media screen and (max-width: 700px) {
        width: 90vw !important;
    }
}