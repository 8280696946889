.team_portfolio {
    max-width: 100%;
    overflow: auto;
    min-height: 100%;
    min-width: 100%;
    
    .header_style {
        padding: 1%;
        background-color: #fafafa;
        font-weight: bold;
        color: #262626;

        .period_class {
            width: 85px;
            min-width: 85px;
            margin-top: 2%;
        }
    }

    .header_style:first-child {
        width: 130px;
        min-width: 130px;
    }

    .row_data_class {
        flex-wrap: nowrap !important;
        border-bottom: 1px solid #e2e2e2;
        width: fit-content;
        margin-bottom: 1%;

        .col_data_class {
            width: 85px;
            padding: 1%;
            display: flex;

            .instrument_name {
                display: inline-block;
                vertical-align: middle;
                margin: auto;
            }

            .input_class {
                display: inline-block;
                vertical-align: middle;
                margin-top: auto;
                margin-bottom: auto;
            }

            .input_class:hover {
                border: 1px solid #d9d9d9;
            }
        }
    
        .col_data_class:first-child {
            width: 130px;
        }

    }

    .last_row {
        margin-top: 6%;
        flex-direction: column;
    }

    .msg_row {
        min-height: 35px;
    }
    
    .btn_row {
        button {
            margin-left: 135px;
            background-color: #1890FF;
            border-color: #1890FF;
            color: white;
        }

        .ant-spin, .ant-spin-spinning {
            margin-left: 160px !important;
            margin-bottom: 1%;
        }
    }

    .success_msg {
        color: #73d13d;
        margin-left: 135px;
        margin-bottom: 1%;
    }

    .fail_msg {
        color: red;
        margin-left: 135px;
        margin-bottom: 1%;
    }

    .leverage_row {
        margin-bottom: 1%;

        .leverage_label {
            display: inline-block;
            vertical-align: middle;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 3px;
            width: 130px;
            text-align: end;
            padding-right: 3%;
        }
    
        .leverage_input {
            width: 85px;
        }

        .leverage_input:hover {
            border: 1px solid #d9d9d9;
        }
    }

    .ant-progress-outer {
        width: 93% !important;
    }

    .red_class {
        color: #f5222d;
    }

    .green_class {
        color: #73d13d;
    }

    .editable_input, .editable_input:hover {
        border: 1px solid #1890FF !important;
    }
}
